import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Artists from '../views/Artists.vue';
import Album from '../views/Album.vue';
import Music from '../views/Music.vue'; 
import Contact from '../views/Contact.vue';
import Demo from '../views/Demo.vue';
import Booking from '../views/Booking.vue';
import Imprint from '../views/Imprint.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:locale',
    component: {
      render: h => h('router-view')
    },
    children: [
      { path: '/', name: 'home', component: Home },
      { path: 'artists', name: 'artists', component: Artists },
      { path: 'music', name: 'music', component: Music },
      { path: 'album/:id', name: 'album', component: Album },
      { path: 'contact', name: 'contact', component: Contact },
      { path: 'demo', name: 'demo', component: Demo },
      { path: 'booking', name: 'booking', component: Booking },
      { path: 'contact', name: 'contact', component: Contact },
      { path: 'imprint', name: 'imprint', component: Imprint },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const getLang = () => {
  const availableLang = ["en", "de"]
  const userLang = navigator.language || navigator.userLanguage; 
  let browserLang = "en";
  let savedLang = "en";
  let selectedLang = "en";

  try {
    browserLang = userLang.split("-")[0];
    savedLang = localStorage.getItem("lang");
    if (savedLang) {
      selectedLang = savedLang;
    } else {
      if (availableLang.includes(browserLang)) {
        selectedLang = browserLang;
      }
    }
  } catch (error) {
    console.log("ERR: could not get saved language or browserlanguage.")
  }

  return selectedLang;
}

router.beforeEach(async (to, from, next) => {
  const lang = getLang();
  if (to.params.locale !== lang) {
    const localizedPath = `/${lang}${to.path}`;
    next(localizedPath);
  } else {
    next();
  }
})


export default router;
