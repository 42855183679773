const localeMapping = {
  en: 'en-US',
  de: 'de-DE',
};

const contenful = {
  fetchUrl: `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`,
  async getContent(query) {
    const fetchOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    };

    try {
      const response = await fetch(this.fetchUrl, fetchOptions).then(
        (response) => response.json()
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error('Could not receive the data from Contentful!');
    }
  },
  async getArtists(lang) {
    const locale = localeMapping[lang];
    const query = `{artistCollection(locale:"${locale}") { items {
            name, genre, image { url }, links, description, orderNumber
        }}}`;
    const response = await this.getContent(query);
    return response.artistCollection.items;
  },
  async getAlbums() {
    const query = `{albumCollection(order: [sys_firstPublishedAt_DESC]) { items {
          title, soundcloudId, cover { url }, streams, streamImagesCollection {
            items {
              sys {
                firstPublishedAt
              }
             url, title
           }
         }
      }}}`;
    const response = await this.getContent(query);
    return response.albumCollection.items;
  },
  async getAbout(lang) {
    const locale = localeMapping[lang];
    const query = `{aboutCollection(locale:"${locale}") { items {
        description
    }}}`;
    const response = await this.getContent(query);
    return response.aboutCollection.items[0];
  },
  async getImprint(lang) {
    const locale = localeMapping[lang];
    const query = `{imprintCollection(locale:"${locale}") { items {
        description {json}, hint {json}
    }}}`;
    const response = await this.getContent(query);
    return response.imprintCollection.items[0];
  },
  async getSocialMediaLinks() {
    const query = `{socialMediaLinksCollection {items {
      label, link, image {url}
    }}}`;
    const response = await this.getContent(query);
    return response.socialMediaLinksCollection.items;
  },
};

export default contenful;
