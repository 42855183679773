import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: colors.green.accent3,
        secondary: colors.lime.accent2,
        accent: colors.grey.darken1,
        error: colors.red.accent3,
      },
    },
  },
});
