<template>
  <v-app>
    <v-container dark fluid class="app">
      <v-app-bar
        absolute
        color="#000"
        dark
        dense
        shrink-on-scroll
        prominent
        src="./assets/bg.jpg"
        fade-img-on-scroll
        scroll-target="#scrolling-techniques"
      >
        <template v-slot:img="{ props }">
          <v-img v-bind="props"></v-img>
          <div class="logo">
            <div class="house left"></div>
            <div class="house right"></div>
          </div>
        </template>

        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <language-switcher></language-switcher>

        <template v-slot:extension>
          <v-tabs align-with-title class="navbar">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab class="nav-tab">
              <router-link
                class="nav-link"
                :to="{ name: 'home', params: { locale: $i18n.locale } }"
                >{{ $t('home') }}</router-link
              >
            </v-tab>
            <v-tab class="nav-tab">
              <router-link
                class="nav-link"
                :to="{ name: 'music', params: { locale: $i18n.locale } }"
                >{{ $t('music') }}</router-link
              >
            </v-tab>
            <v-tab class="nav-tab">
              <router-link
                class="nav-link"
                :to="{ name: 'artists', params: { locale: $i18n.locale } }"
                >{{ $t('artists') }}</router-link
              >
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" absolute bottom temporary>
        <v-list nav dense>
          <v-list-item-group v-model="group">
            <router-link
              :to="{ name: 'booking', params: { locale: $i18n.locale } }"
            >
              <v-list-item>
                <v-list-item-title>
                  {{ $t('bookings') }}
                </v-list-item-title>
              </v-list-item>
            </router-link>
            <router-link
              :to="{ name: 'demo', params: { locale: $i18n.locale } }"
            >
              <v-list-item>
                <v-list-item-title>
                  {{ $t('demoDrop') }}
                </v-list-item-title>
              </v-list-item>
            </router-link>
            <router-link
              :to="{ name: 'contact', params: { locale: $i18n.locale } }"
            >
              <v-list-item>
                <v-list-item-title>
                  {{ $t('contact') }}
                </v-list-item-title>
              </v-list-item>
            </router-link>
            <router-link
              :to="{ name: 'imprint', params: { locale: $i18n.locale } }"
            >
              <v-list-item>
                <v-list-item-title>
                  {{ $t('imprint') }}
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-sheet id="scrolling-techniques" class="sheet">
        <v-container class="content-container">
          <router-view />
        </v-container>
      </v-sheet>
    </v-container>
  </v-app>
</template>

<script>
import LanguageSwitcher from './components/LanguageSwitcher.vue';
export default {
  components: {
    LanguageSwitcher,
  },
  data: function () {
    return {
      about: '',
      drawer: false,
      group: null,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  async mounted() {
    const content = await this.getContent();
    this.about = content.aboutCollection.items[0].description;
  },
  methods: {
    async getContent() {
      const query = `{
        aboutCollection(locale: "en-US") {items{description}}
      }`;

      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}`;
      const fetchOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
        }),
      };

      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) =>
          response.json()
        );
        return response.data;
      } catch (error) {
        console.log(error);
        throw new Error('Could not receive the data from Contentful!');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sheet {
  max-height: 100vh;
  overflow-y: scroll;
  background: url(./assets/bg_tile.jpg) repeat 0 0 #fff;
}

.content-container {
  min-height: 100vh;
  padding-top: 220px;
}

.navbar {
  margin-top: 22px;
  .nav-tab {
    width: 80px;
  }
  a {
    color: var(--v-accent-base);
    font-weight: bold;
  }
  .v-tab--active {
    a {
      color: var(--v-secondary-base);
      font-weight: normal;
    }
  }

  .nav-link {
    display: block;
    height: 100%;
    text-align: center;
    line-height: 48px;
    width: 90px;
    position: absolute;
  }
}

.logo {
  width: 70px;
  height: 90px;
  background-image: url(./assets/logo_black.jpg);
  background-size: contain;
  position: absolute;
  left: 40%;
  top: 20%;
  box-shadow: 0px 0px 30px 15px black;
  @media (min-width: 768px) and (max-width: 979px) {
    left: 45%;
  }
  @media (min-width: 980px) {
    width: 93px;
    height: 120px;
    left: 47%;
    top: 5%;
  }
}

.house {
  background-image: url(./assets/house_b.png);
  width: 200px;
  height: 100px;
  background-size: contain;
  position: absolute;
  left: -125px;
  bottom: -25px;

  &.right {
    left: auto;
    right: -125px;
    bottom: -25px;
    transform: scaleX(-1);
  }
}

.content-container {
  max-width: 1185px !important;
}
</style>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;900&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  max-height: 100vh;
}

.v-toolbar {
  &__extension {
    background: #000;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.app.container--fluid {
  padding: 0 !important;
}

a,
a:link,
a:visited,
.link-reorder {
  text-decoration: none;
  font-weight: normal;
}

.navbar {
  .v-slide-group__wrapper {
    background-color: black;
  }
}
</style>
