<template>
  <div class="locale-changer">
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon text v-on="on" v-bind="attrs">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            class="language-item"
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
          >
            <v-list-item-title @click="handleClick(lang)">{{
              lang.label
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return {
      langs: [
        { code: 'en', label: 'english' }, 
        { code: 'de', label: 'deutsch' },
      ],
    };
  },
  methods: {
    handleClick(lang) {
      if (this.$i18n.locale !== lang.code) {
        try {
          localStorage.setItem("lang", lang.code)
        } catch (error) {
          console.log("ERR: could not save lang in localstorage");
        }
        this.$i18n.locale = lang.code;
        const to = this.$router.resolve({ params: { locale: lang.code } });
        this.$router.push(to.location);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.language-item {
  cursor: pointer;
}
</style>
