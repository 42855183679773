<template>
  <v-row>
    <v-col md="6" v-for="artist in artists" :key="artist.name" class="artist">
      <v-card :loading="loading" class="mx-auto" dark>
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-img :src="artist.image.url" cover></v-img>

        <v-card-title>{{ artist.name }}</v-card-title>

        <v-card-text>
          <div>{{ artist.description }}</div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-text>
          <v-chip-group active-class="primary" column>
            <v-chip v-for="genre in artist.genre" :key="genre">{{
              genre
            }}</v-chip>
          </v-chip-group>
        </v-card-text>

        <div class="link-wrapper">
          <v-btn
            color="secondary"
            text
            :href="link.src"
            v-for="link in artist.links"
            :key="link.src"
            x-small
          >
            {{ link.label }}
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import contentful from '../providers/contentful';

export default {
  data() {
    return {
      loading: true,
      artists: [],
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.getContent();
    },
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const artists = await contentful.getArtists(this.$i18n.locale);
      this.artists = artists.sort(this.compare);
      this.loading = false;
    },
    compare(a, b) {
      if (a.orderNumber < b.orderNumber) {
        return -1;
      }
      if (a.orderNumber > b.orderNumber) {
        return 1;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.link-wrapper {
  width: 100%;
  padding: 15px;
}

.artist {
  flex-basis: initial;
}
</style>
