<template>
  <div>
    <v-row>
      <v-col
        xs="6"
        sm="4"
        v-for="album in albums"
        :key="album.soundcloudId"
        class="album"
      >
        <v-card class="mx-auto" dark>
          <template slot="progress">
            <v-progress-linear
              color="secondary"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <div @click.stop="handleClickAlbum(album)" class="album-cover">
            <v-img :src="album.cover.url"></v-img>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog v-model="dialog" max-width="460" v-if="activeAlbum">
          <v-card>
            <v-icon class="close-trigger" @click="dialog = false">
              mdi-close
            </v-icon>
            <v-img :src="activeAlbum.cover.url"></v-img>
            <v-card-text class="stream-content">
              <div class="stream-action-banner">
                <div class="desc">Stream/Download</div>
              </div>
              <div class="streams">
                <a
                  :href="stream.link"
                  target="_blank"
                  v-for="stream in activeAlbum.streams"
                  :key="stream.type"
                >
                  <div class="stream">
                    <v-row align="center" justify="center">
                      <v-col cols="3">
                        <img
                          class="social-media-icon"
                          :src="getStreamImage(stream.type)"
                          :alt="stream.type"
                        />
                      </v-col>
                      <v-col>
                        <div class="label">
                          {{ stream.label }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="cta">
                          <v-btn>Stream</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </a>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import contentful from '../providers/contentful';

export default {
  data() {
    return {
      albums: [],
      activeAlbum: null,
      streamImages: {},
      dialog: false,
    };
  },
  async mounted() {
    this.albums = await contentful.getAlbums();
  },
  methods: {
    handleClickAlbum(album) {
      this.activeAlbum = album;
      this.dialog = true;
    },
    getStreamImage(type) {
      const image = this.activeAlbum.streamImagesCollection.items.find(
        (element) => {
          const title = element.title.toLowerCase();
          if (title.includes(type)) {
            return true;
          }
        }
      );

      return image ? image.url : "";
    },
  },
};
</script>

<style scoped lang="scss">
.streams-dialog {
  overflow-x: hidden;
}
.close-trigger {
    color: #fff;
    position: absolute;
    z-index: 204;
    top: 0;
    right: 0;
    width: 30px;
    cursor: pointer;
    height: 35px;
}

.link-wrapper {
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.album {
  flex-basis: initial;
}

.album-cover {
  cursor: pointer;
}
.stream-action-banner {
  text-align: center;
  padding: 20px 10px;

  .name {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .desc {
    font-size: 24px;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    /* bottom: 13px; */
    margin: 0 auto;
    transform: translateY(10px);
    width: 0;
    height: 0;
    border-top: 25px solid #1e1e1e;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
  }
}

.streams {
  background: #fff;

  a {
    color: #1e1e1e;
    background: #fff;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #1e1e1e;
      text-decoration: none;
      div {
        background: #fafafa;
      }
    }
    .stream {
      padding: 30px;
      border-bottom: 1px solid #dadadd;
      background: #fff;

      .label {
        text-align: left;
        font-size: 14px;
        font-weight: bold;

        @media (min-width: 768px) {
          font-size: 24px;
        }
      }

      .cta {
        text-align: right;
      }
    }
  }
}

.stream-content {
  padding: 0 !important;
}

.social-media-icon {
  width: 60px;
}
</style>
<style>
.v-dialog {
  overflow-x: hidden;
}
</style>
