<template>
  <v-container>
    <v-row>
      <v-col>
     <router-link :to="{ name: 'music'}">
            <v-btn color="primary">  {{ $t('back') }} </v-btn>
          </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <iframe
            name="soundcloud-iframe"
            width="100%"
            height="600"
            scrolling="no"
            frameborder="no"
            allow="autoplay"
            :src="`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${$route.params.id}&color=%231cff98&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`"
          ></iframe>
          <div
            style="
              font-size: 10px;
              color: #ffffff;
              line-break: anywhere;
              word-break: normal;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-family: Interstate, Lucida Grande, Lucida Sans Unicode,
                Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
              font-weight: 100;
            "
          ></div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
