import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const availableLang = ["en", "de"]
const userLang = navigator.language || navigator.userLanguage; 
let browserLang = "en";
let savedLang = "en";
let selectedLang = "en";

try {
  browserLang = userLang.split("-")[0];
  savedLang = localStorage.getItem("lang");
  if (savedLang) {
    selectedLang = savedLang;
  } else {
    if (availableLang.includes(browserLang)) {
      selectedLang = browserLang;
    }
  }
} catch (error) {
  console.log("ERR: could not get saved language or browserlanguage.")
}

export default new VueI18n({
  locale: selectedLang,
  fallbackLocale: selectedLang,
  messages: loadLocaleMessages()
})
