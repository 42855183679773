<template>
  <ContactForm
    :title="$t('contactTitle')"
    toAddress="info@plattenbau-music.de"
    :formMessage="$t('contactHint')"
  ></ContactForm>
</template>

<script>
import ContactForm from '../components/ContactForm.vue';

export default {
  components: {
    ContactForm
  },
  data() {
    return {};
  }
};
</script>

<style></style>
