<template>
  <div class="loader">
    <div v-for="index in 10" class="bar" :key="index"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loader {
  height: 90px;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  top: 30%;
  width: 300px;
  transform: translateX(-50%);
}

.bar {
  background: #00e676;
  bottom: 1px;
  height: 90px;
  position: absolute;
  width: 29px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 3px;
  }
  100% {
    opacity: 1;
    height: 90px;
  }
}

.bar:nth-child(1) {
  left: 0px;
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  left: 30px;
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  left: 60px;
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  left: 90px;
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  left: 120px;
  animation-duration: 400ms;
}
.bar:nth-child(6) {
  left: 150px;
  animation-duration: 427ms;
}
.bar:nth-child(7) {
  left: 180px;
  animation-duration: 441ms;
}
.bar:nth-child(8) {
  left: 210px;
  animation-duration: 419ms;
}
.bar:nth-child(9) {
  left: 240px;
  animation-duration: 487ms;
}
.bar:nth-child(10) {
  left: 270px;
  animation-duration: 442ms;
}
</style>
