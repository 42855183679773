<template>
    <v-row>
      <v-col>
        <v-card>
            <v-card-title>{{ title }}</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="contactForm.name"
                :label="$t('name')"
                :rules="requiredRules"
                required
              ></v-text-field>
              <v-text-field
                v-model="contactForm.email"
                :rules="emailRules"
                :label="$t('email')"
                required
              ></v-text-field>
              <v-text-field
                v-model="contactForm.subject"
                :rules="requiredRules"
                :label="$t('subject')"
                required
              ></v-text-field>
              <v-textarea
                name="input-7-1"
                :label="$t('message')"
                :rules="requiredRules"
                v-model="contactForm.message"
                :hint="formMessage"
              ></v-textarea>
              <v-btn class="mr-4 mt-5" @click="handleSubmit">
                {{ $t('submit') }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar">
          {{ $t('formSuccess') }}
  
          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    props: ["title", "toAddress", "formMessage"],
    data() {
      return {
        contactForm: {
          name: '',
          email: '',
          subject: '',
          message: '',
        },
        snackbar: false,
        valid: true,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        requiredRules: [(v) => !!v || 'Field is required'],
      };
    },
    methods: {
      submitToServer() {
        const payload = this.contactForm;
        payload.to = this.toAddress;
  
        return new Promise((resolve, reject) => {
          fetch(
            `${process.env.VUE_APP_NETLIFY_FUNCTIONS_PROTOCOL}://${process.env.VUE_APP_NETLIFY_FUNCTIONS_HOST}/.netlify/functions/mail`,
            {
              method: 'POST',
              body: JSON.stringify(payload),
            }
          )
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
      handleSubmit() {
        const valid = this.$refs.form.validate();
        if (!valid) return;
        this.submitToServer().then((response) => {
          if (Number(response.status) !== 200) {
            console.log('Error submitting the form.');
          } else {
            this.snackbar = true;
            this.$refs.form.reset();
          }
        });
      },
    },
  };
  </script>
  
  <style></style>
  