<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <v-card :loading="loading">
            <v-card-text>
              <RichTextRenderer :document="imprint" v-if="imprint.nodeType" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card :loading="loading">
            <v-card-text>
              <RichTextRenderer :document="hint"  v-if="imprint.nodeType" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import contentful from '../providers/contentful';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
  components: {
    RichTextRenderer,
  },
  data() {
    return {
      loading: true,
      imprint: {},
      hint: {},
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.getContent();
    },
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const imprintContent = await contentful.getImprint(this.$i18n.locale);
      this.imprint = imprintContent.description.json;
      this.hint = imprintContent.hint.json;
      this.loading = false;
    },
  },
};
</script>

<style>
h1 {
  margin-bottom: 20px;
}
</style>
