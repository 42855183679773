<template>
  <v-container :class="{ loading }">
    <loader></loader>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col v-for="icon in socialMediaLinks" cols="2" :key="icon.label">
        <a :href="icon.link" target="_blank">
          <img
            class="social-media-icon"
            :src="icon.image.url"
            :alt="icon.label"
          />
        </a>
      </v-col>
      <v-col cols="1"></v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <router-link
          color="secondary"
          tag="button"
          :to="{ name: 'booking', params: { locale: $i18n.locale } }"
        >
          <v-btn color="#1bab65">
            {{ $t('bookingCta') }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="loading">
          <v-card-text>
            <div class="text--primary">
              {{ about }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <router-link
          color="secondary"
          tag="button"
          :to="{ name: 'demo', params: { locale: $i18n.locale } }"
        >
          <v-btn color="#1bab65">
            {{ $t('demoHint') }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col>
        <v-card>
          <v-card-title class="justify-center">{{
            $t('spotifyReleases')
          }}</v-card-title>
          <iframe
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/playlist/3x5rjPaZ1EpkaK10FdF2IS?utm_source=generator&theme=0"
            width="100%"
            height="352"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import contentful from '../providers/contentful';
import Loader from '../components/Loader';

export default {
  components: {
    Loader,
  },
  data() {
    return {
      socialMediaLinks: [],
      loading: true,
      about: '',
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.getContent();
    },
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const result = await contentful.getAbout(this.$i18n.locale);
      this.about = result.description;
      this.socialMediaLinks = await contentful.getSocialMediaLinks();
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.social-media-icon {
  max-width: 100%;
  width: 100px;
}
.container {
  .loader {
    display: none;
  }

  &.loading .loader {
    display: block;
  }
}
.container.loading div:not(.loader, .bar) {
  display: none;
}
</style>
